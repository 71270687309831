/*------------------------------------*\
    MATERIAL PHOTO GALLERY
\*------------------------------------*/

$gallery-width: 860px;
$transition-duration: 0.5s;

.m-p-g {
	max-width: $gallery-width;
	margin: 0 auto;
	
	&__thumbs {
		
		&-img {
			margin: 0;
			float: left;
			vertical-align: bottom;
			cursor: pointer;
			z-index: 1;
			position: relative;
			opacity: 0;
			filter: brightness(100%);
			-webkit-tap-highlight-color: rgba(black, 0);
			will-change: opacity, transform;
			transition: all $transition-duration cubic-bezier(0.23, 1, 0.32, 1);
			
			&.active {
				z-index: 50;
			}
			
			&.layout-completed {
				opacity: 1;
			}

			&.hide {
				opacity: 0;
			}
			
			&:hover {
				filter: brightness(110%);
			}
		}
	}
	
	&__fullscreen {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100vh;
		background: rgba(black, 0);
		visibility: hidden;
		transition:
			background .25s ease-out,
			visibility .01s $transition-duration linear;
		will-change: background, visibility;
		backface-visibility: hidden;

		&.active {
			transition:
				background .25s ease-out,
				visibility .01s 0s linear;
			visibility: visible;
			background: rgba(black, 0.95);
		}

		&-img {
			pointer-events: none;
			position: absolute;
			transform-origin: left top;
			top: 50%;
			left: 50%;
			max-height: 100vh;
			max-width: 100%;
			visibility: hidden;
			will-change: visibility;
			transition: opacity $transition-duration ease-out;

			&.active {
				visibility: visible;
				opacity: 1 !important;
				transition:
					transform $transition-duration cubic-bezier(0.23, 1, 0.32, 1),
					opacity $transition-duration ease-out;
			}

			&.almost-active {
				opacity: 0;
				transform: translate3d(0,0,0) !important;
			}
		}
	}
	
	&__controls {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 200;
		height: 20vh;
		background: linear-gradient(to top, transparent 0%, rgba(black, 0.55) 100%);
		opacity: 0;
		visibility: hidden;
		transition: all $transition-duration cubic-bezier(0.23, 1, 0.32, 1);
		
		&.active {
			opacity: 1;
			visibility: visible;
		}
		
		&-close,
		&-arrow {
			appearance: none;
			border: none;
			background: none;

			&:focus {
				outline: none;
			}
		}

		&-arrow {
			position: absolute;
			z-index: 1;
			top: 0;
			width: 20%;
			height: 100vh;
			display: flex;
			align-items: center;
			cursor: pointer;
			-webkit-tap-highlight-color: rgba(black, 0);
			opacity: 0;
			
			&:hover {
				opacity: 1;
			}
			
			&--prev {
				left: 0;
				padding-left: 3vw;
				justify-content: flex-start;
			}
			
			&--next {
				right: 0;
				padding-right: 3vw;
				justify-content: flex-end;
			}
		}
		
		&-close {
			position: absolute;
			top: 3vh;
			left: 3vw;
			z-index: 5;
			cursor: pointer;
			-webkit-tap-highlight-color: rgba(black, 0);
		}
	}

	&__btn {
		$size: 50px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: $size;
		height: $size;
		border-radius: 50%;
		background: rgba(white, 0.07);
		transition: all .25s ease-out;

		&:hover {
			background: rgba(white, 0.15);
		}
	}

	&__alertBox {
		position: fixed;
		z-index: 999;
		max-width: 700px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		padding: 25px;
		border-radius: 3px;
		text-align: center;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
		color: grey;

		h2 {
			color: red;
		}
	}
}